import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import a from "../Assets/theater/oh-fish office/1D6A5339.jpg";
import b from "../Assets/theater/oh-fish office/1D6A5351.jpg";
import c from "../Assets/theater/oh-fish office/1D6A5355.jpg";
import d from "../Assets/theater/oh-fish office/1D6A5367.jpg";
import e from "../Assets/theater/oh-fish office/1D6A5369.jpg";
import f from "../Assets/theater/oh-fish office/1D6A5371.jpg";
import g from "../Assets/theater/oh-fish office/1D6A5376.jpg";
import h from "../Assets/theater/oh-fish office/1D6A5394.jpg";
import i from "../Assets/theater/oh-fish office/1D6A5396.jpg";
import j from "../Assets/theater/oh-fish office/1D6A5399.jpg";
import k from "../Assets/theater/oh-fish office/1D6A5404.jpg";
import l from "../Assets/theater/oh-fish office/1D6A5408.jpg";
import m from "../Assets/theater/oh-fish office/1D6A5412.jpg";
import n from "../Assets/theater/oh-fish office/1D6A5415.jpg";
import o from "../Assets/theater/oh-fish office/1D6A5420.jpg";
import p from "../Assets/theater/oh-fish office/1D6A5423.jpg";
import q from "../Assets/theater/oh-fish office/1D6A5429.jpg";
import r from "../Assets/theater/oh-fish office/1D6A5432.jpg";
import s from "../Assets/theater/oh-fish office/1D6A5443.jpg";
import t from "../Assets/theater/oh-fish office/1D6A5449.jpg";
import u from "../Assets/theater/oh-fish office/1D6A5455.jpg";
import v from "../Assets/theater/oh-fish office/1D6A5459.jpg";
import w from "../Assets/theater/oh-fish office/1D6A5461.jpg";
import y from "../Assets/theater/oh-fish office/1D6A5471.jpg";
import z from "../Assets/theater/oh-fish office/1D6A5475.jpg";

import aa from "../Assets/theater/oh-fish office/1D6A5479.jpg";
import ab from "../Assets/theater/oh-fish office/1D6A5483.jpg";
import ac from "../Assets/theater/oh-fish office/1D6A5493.jpg";
import ad from "../Assets/theater/oh-fish office/1D6A5497.jpg";
import ae from "../Assets/theater/oh-fish office/1D6A5500.jpg";
import af from "../Assets/theater/oh-fish office/1D6A5511.jpg";
import ag from "../Assets/theater/oh-fish office/1D6A5513.jpg";
import ah from "../Assets/theater/oh-fish office/1D6A5517.jpg";
import ai from "../Assets/theater/oh-fish office/1D6A5527.jpg";
import ak from "../Assets/theater/oh-fish office/1D6A5529.jpg";
import al from "../Assets/theater/oh-fish office/1D6A5533.jpg";
import am from "../Assets/theater/oh-fish office/1D6A5536.jpg";
import an from "../Assets/theater/oh-fish office/1D6A5542.jpg";
import ao from "../Assets/theater/oh-fish office/1D6A5546.jpg";
import ap from "../Assets/theater/oh-fish office/1D6A5548.jpg";
import aq from "../Assets/theater/oh-fish office/1D6A5554.jpg";
import ar from "../Assets/theater/oh-fish office/1D6A5556.jpg";
import as from "../Assets/theater/oh-fish office/1D6A5559.jpg";
import at from "../Assets/theater/oh-fish office/1D6A5561.jpg";
import au from "../Assets/theater/oh-fish office/1D6A5563.jpg";
import av from "../Assets/theater/oh-fish office/1D6A5569.jpg";
import aw from "../Assets/theater/oh-fish office/1D6A5572.jpg";
import ax from "../Assets/theater/oh-fish office/1D6A5579.jpg";
import ay from "../Assets/theater/oh-fish office/1D6A5585.jpg";
import az from "../Assets/theater/oh-fish office/1D6A5588.jpg";
import img1 from "../Assets/theater/oh-fish office/1D6A5590.jpg";
import img2 from "../Assets/theater/oh-fish office/1D6A5596.jpg";
import img3 from "../Assets/theater/oh-fish office/1D6A5598.jpg";
import img4 from "../Assets/theater/oh-fish office/1D6A5605.jpg";
import img5 from "../Assets/theater/oh-fish office/1D6A5612.jpg";
import img6 from "../Assets/theater/oh-fish office/1D6A5614.jpg";
import img7 from "../Assets/theater/oh-fish office/1D6A5615.jpg";
import img8 from "../Assets/theater/oh-fish office/1D6A5617.jpg";
import img9 from "../Assets/theater/oh-fish office/1D6A5624.jpg";
import img10 from "../Assets/theater/oh-fish office/1D6A5632.jpg";
import img11 from "../Assets/theater/oh-fish office/1D6A5636.jpg";
import img12 from "../Assets/theater/oh-fish office/1D6A5647.jpg";
import img13 from "../Assets/theater/oh-fish office/1D6A5668.jpg";
import img14 from "../Assets/theater/oh-fish office/1D6A5670.jpg";
import img15 from "../Assets/theater/oh-fish office/1D6A5678.jpg";
import img16 from "../Assets/theater/oh-fish office/1D6A5683.jpg";
import img17 from "../Assets/theater/oh-fish office/1D6A5685.jpg";
import img18 from "../Assets/theater/oh-fish office/1D6A5693.jpg";
import img19 from "../Assets/theater/oh-fish office/1D6A5696.jpg";
import img20 from "../Assets/theater/oh-fish office/1D6A5699.jpg";
import img21 from "../Assets/theater/oh-fish office/1D6A5701.jpg";
import img22 from "../Assets/theater/oh-fish office/1D6A5702.jpg";
import img23 from "../Assets/theater/oh-fish office/1D6A5704.jpg";
import img24 from "../Assets/theater/oh-fish office/1D6A5711.jpg";
import img25 from "../Assets/theater/oh-fish office/1D6A5714.jpg";
import img26 from "../Assets/theater/oh-fish office/1D6A5718.jpg";
import img27 from "../Assets/theater/oh-fish office/1D6A5723.jpg";
import img28 from "../Assets/theater/oh-fish office/1D6A5728.jpg";
import img29 from "../Assets/theater/oh-fish office/1D6A5734.jpg";
import img30 from "../Assets/theater/oh-fish office/1D6A5741.jpg";
import img31 from "../Assets/theater/oh-fish office/1D6A5744.jpg";
import img32 from "../Assets/theater/oh-fish office/1D6A5747.jpg";
import img33 from "../Assets/theater/oh-fish office/1D6A5753.jpg";
import img34 from "../Assets/theater/oh-fish office/1D6A5756.jpg";
import img35 from "../Assets/theater/oh-fish office/1D6A5760.jpg";
import img36 from "../Assets/theater/oh-fish office/1D6A5774.jpg";
import img37 from "../Assets/theater/oh-fish office/1D6A5778.jpg";
import img38 from "../Assets/theater/oh-fish office/1D6A5791.jpg";
import img39 from "../Assets/theater/oh-fish office/1D6A5804.jpg";
import img40 from "../Assets/theater/oh-fish office/1D6A5806.jpg";
import img41 from "../Assets/theater/oh-fish office/1D6A5810.jpg";
import img42 from "../Assets/theater/oh-fish office/1D6A5813.jpg";
import img43 from "../Assets/theater/oh-fish office/1D6A5817.jpg";
import img44 from "../Assets/theater/oh-fish office/1D6A5823.jpg";
import img45 from "../Assets/theater/oh-fish office/1D6A5828.jpg";
import img46 from "../Assets/theater/oh-fish office/1D6A5843.jpg";
import img47 from "../Assets/theater/oh-fish office/1D6A5849.jpg";
import img48 from "../Assets/theater/oh-fish office/1D6A5853.jpg";
import img49 from "../Assets/theater/oh-fish office/1D6A5858.jpg";
import img50 from "../Assets/theater/oh-fish office/1D6A5859.jpg";
import img51 from "../Assets/theater/oh-fish office/1D6A5876.jpg";
import img52 from "../Assets/theater/oh-fish office/1D6A5885.jpg";
import img53 from "../Assets/theater/oh-fish office/1D6A5906.jpg";
import img54 from "../Assets/theater/oh-fish office/1D6A5909.jpg";
import img55 from "../Assets/theater/oh-fish office/1D6A5922.jpg";
import img56 from "../Assets/theater/oh-fish office/1D6A5924.jpg";
import img57 from "../Assets/theater/oh-fish office/1D6A5927.jpg";
import img58 from "../Assets/theater/oh-fish office/1D6A5933.jpg";
import img59 from "../Assets/theater/oh-fish office/1D6A5942.jpg";
import img60 from "../Assets/theater/oh-fish office/1D6A5949.jpg";
import img61 from "../Assets/theater/oh-fish office/1D6A5952.jpg";
import img62 from "../Assets/theater/oh-fish office/1D6A5953.jpg";
import img63 from "../Assets/theater/oh-fish office/1D6A5958.jpg";
import img64 from "../Assets/theater/oh-fish office/1D6A5959.jpg";
import img65 from "../Assets/theater/oh-fish office/1D6A5970.jpg";
import img66 from "../Assets/theater/oh-fish office/1D6A5977.jpg";
import img67 from "../Assets/theater/oh-fish office/1D6A5980.jpg";
import img68 from "../Assets/theater/oh-fish office/1D6A5983.jpg";
import img69 from "../Assets/theater/oh-fish office/1D6A5989.jpg";
import img70 from "../Assets/theater/oh-fish office/1D6A5996.jpg";
import img71 from "../Assets/theater/oh-fish office/1D6A5999.jpg";
import img72 from "../Assets/theater/oh-fish office/1D6A6004.jpg";
import img73 from "../Assets/theater/oh-fish office/1D6A6014.jpg";
import img74 from "../Assets/theater/oh-fish office/1D6A6058.jpg";
import img75 from "../Assets/theater/oh-fish office/1D6A6068.jpg";

const Theater_Ohfish_Office = () => {
  const images = [
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p,
    q,
    r,
    s,
    t,
    u,
    v,
    w,
    y,
    z,
    aa,
    ab,
    ac,
    ad,
    ae,
    af,
    ag,
    ah,
    ai,
    ak,
    al,
    am,
    an,
    ao,
    ap,
    aq,
    ar,
    as,
    at,
    au,
    av,
    aw,
    ax,
    ay,
    az,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    img60,
    img61,
    img62,
    img63,
    img64,
    img65,
    img66,
    img67,
    img68,
    img69,
    img70,
    img71,
    img72,
    img73,
    img74,
    img75,
  ];
  return (
    <div>
      <Header />
      <h1  style={{textAlign:'center',margin:'10px auto'}}>
        <u>Oh-Fish Office</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="item col-sm-6 col-md-3 mb-3" key={index}>
              <a href={image} className="fancybox" data-fancybox="gallery1">
                <img
                  src={image}
                  width="100%"
                  className="img-fluid height_set"
                  alt={`Slide ${index + 1}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};


export default Theater_Ohfish_Office;
