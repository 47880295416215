import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import FAB_4586 from "../Assets/theater/ek bakwaas love story/FAB_4586.jpg";
import FAB_4587 from "../Assets/theater/ek bakwaas love story/FAB_4587.jpg";
import FAB_4588 from "../Assets/theater/ek bakwaas love story/FAB_4588.jpg";
import FAB_4589 from "../Assets/theater/ek bakwaas love story/FAB_4589.jpg";
import FAB_4590 from "../Assets/theater/ek bakwaas love story/FAB_4590.jpg";
import FAB_4655 from "../Assets/theater/ek bakwaas love story/FAB_4655.jpg";
import FAB_4656 from "../Assets/theater/ek bakwaas love story/FAB_4656.jpg";
import FAB_4661 from "../Assets/theater/ek bakwaas love story/FAB_4661.jpg";
import FAB_4694 from "../Assets/theater/ek bakwaas love story/FAB_4694.jpg";
import FAB_4707 from "../Assets/theater/ek bakwaas love story/FAB_4707.jpg";
import FAB_4720 from "../Assets/theater/ek bakwaas love story/FAB_4720.jpg";

import a from "../Assets/theater/ek bakwaas love story/FAB_4724.jpg";
import b from "../Assets/theater/ek bakwaas love story/FAB_4724.jpg";
import c from "../Assets/theater/ek bakwaas love story/FAB_4728.jpg";
import d from "../Assets/theater/ek bakwaas love story/FAB_4729.jpg";
import e from "../Assets/theater/ek bakwaas love story/FAB_4730.jpg";
import f from "../Assets/theater/ek bakwaas love story/FAB_4748.jpg";
import g from "../Assets/theater/ek bakwaas love story/FAB_4755.jpg";
import h from "../Assets/theater/ek bakwaas love story/FAB_4757.jpg";
import i from "../Assets/theater/ek bakwaas love story/FAB_4763.jpg";
import j from "../Assets/theater/ek bakwaas love story/FAB_4767.jpg";
import k from "../Assets/theater/ek bakwaas love story/FAB_4768.jpg";
import l from "../Assets/theater/ek bakwaas love story/FAB_4770.jpg";
import m from "../Assets/theater/ek bakwaas love story/FAB_4773.jpg";
import n from "../Assets/theater/ek bakwaas love story/FAB_4777.jpg";
import o from "../Assets/theater/ek bakwaas love story/FAB_4780.jpg";
import p from "../Assets/theater/ek bakwaas love story/FAB_4785.jpg";
import q from "../Assets/theater/ek bakwaas love story/FAB_4788.jpg";
import r from "../Assets/theater/ek bakwaas love story/FAB_4798.jpg";
import s from "../Assets/theater/ek bakwaas love story/FAB_4807.jpg";
import t from "../Assets/theater/ek bakwaas love story/FAB_4804.jpg";
import u from "../Assets/theater/ek bakwaas love story/FAB_4811.jpg";
import v from "../Assets/theater/ek bakwaas love story/FAB_4817.jpg";
import w from "../Assets/theater/ek bakwaas love story/FAB_4826.jpg";
import x from "../Assets/theater/ek bakwaas love story/FAB_4844.jpg";
import y from "../Assets/theater/ek bakwaas love story/FAB_4847.jpg";
import z from "../Assets/theater/ek bakwaas love story/FAB_4853.jpg";
import aa from "../Assets/theater/ek bakwaas love story/FAB_4861.jpg";
import ab from "../Assets/theater/ek bakwaas love story/FAB_4865.jpg";
import ac from "../Assets/theater/ek bakwaas love story/FAB_4883.jpg";
import ad from "../Assets/theater/ek bakwaas love story/FAB_4886.jpg";
import ae from "../Assets/theater/ek bakwaas love story/FAB_4888.jpg";
import af from "../Assets/theater/ek bakwaas love story/FAB_4890.jpg";
import ag from "../Assets/theater/ek bakwaas love story/FAB_4901.jpg";
import ah from "../Assets/theater/ek bakwaas love story/FAB_4909.jpg";
import ai from "../Assets/theater/ek bakwaas love story/FAB_4912.jpg";
import aj from "../Assets/theater/ek bakwaas love story/FAB_4917.jpg";
import ak from "../Assets/theater/ek bakwaas love story/FAB_4921.jpg";
import al from "../Assets/theater/ek bakwaas love story/FAB_4932.jpg";
import am from "../Assets/theater/ek bakwaas love story/FAB_4941.jpg";
import an from "../Assets/theater/ek bakwaas love story/FAB_4957.jpg";
import ao from "../Assets/theater/ek bakwaas love story/FAB_4960.jpg";
import ap from "../Assets/theater/ek bakwaas love story/FAB_4980.jpg";
import aq from "../Assets/theater/ek bakwaas love story/FAB_4982.jpg";
import ar from "../Assets/theater/ek bakwaas love story/FAB_4990.jpg";
import as from "../Assets/theater/ek bakwaas love story/FAB_4995.jpg";
import at from "../Assets/theater/ek bakwaas love story/FAB_5011.jpg";
import au from "../Assets/theater/ek bakwaas love story/FAB_5026.jpg";
import av from "../Assets/theater/ek bakwaas love story/FAB_5033.jpg";
import aw from "../Assets/theater/ek bakwaas love story/FAB_5035.jpg";
import ax from "../Assets/theater/ek bakwaas love story/FAB_5042.jpg";
import ay from "../Assets/theater/ek bakwaas love story/FAB_5046.jpg";
import az from "../Assets/theater/ek bakwaas love story/FAB_5060.jpg";
import ba from "../Assets/theater/ek bakwaas love story/FAB_5066.jpg";
import bb from "../Assets/theater/ek bakwaas love story/FAB_5071 (1).jpg";
import bc from "../Assets/theater/ek bakwaas love story/FAB_5076.jpg";
import bd from "../Assets/theater/ek bakwaas love story/FAB_5084.jpg";
import be from "../Assets/theater/ek bakwaas love story/FAB_5087.jpg";
import bf from "../Assets/theater/ek bakwaas love story/FAB_5098.jpg";
import bg from "../Assets/theater/ek bakwaas love story/FAB_5104.jpg";
import bh from "../Assets/theater/ek bakwaas love story/FAB_5109.jpg";
import bi from "../Assets/theater/ek bakwaas love story/FAB_5114.jpg";
import bj from "../Assets/theater/ek bakwaas love story/FAB_5181.jpg";
import bk from "../Assets/theater/ek bakwaas love story/FAB_5182.jpg";
import bl from "../Assets/theater/ek bakwaas love story/FAB_5198.jpg";
import bm from "../Assets/theater/ek bakwaas love story/FAB_5201.jpg";

const Theater_Ek_Bakwaas_Love_Story = () => {
  const images = [
    FAB_4586,
    FAB_4587,
    FAB_4586,
    FAB_4587,
    FAB_4588,
    FAB_4589,
    FAB_4590,
    FAB_4655,
    FAB_4656,
    FAB_4661,
    FAB_4694,
    FAB_4707,
    FAB_4720,
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p,
    q,
    r,
    s,
    t,
    u,
    v,
    w,
    x,
    y,
    z,
    aa,
    ab,
    ac,
    ad,
    ae,
    af,
    ag,
    ah,
    ai,
    aj,
    ak,
    al,
    am,
    an,
    ao,
    ap,
    aq,
    ar,
    as,
    at,
    au,
    av,
    aw,
    ax,
    ay,
    az,
    ba,
    bb,
    bc,
    bd,
    be,
    bf,
    bg,
    bh,
    bi,
    bj,
    bk,
    bl,
    bm,
  ];
  return (
    <div>
      <Header />
      <h1 style={{textAlign:'center',margin:'10px auto'}}>
        <u>Ek Bakwaas Love Story</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="item col-sm-6 col-md-3 mb-3" key={index}>
              <a href={image} className="fancybox" data-fancybox="gallery1">
                <img
                  src={image}
                  width="100%"
                  className="img-fluid height_set"
                  alt={`Slide ${index + 1}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Theater_Ek_Bakwaas_Love_Story;
