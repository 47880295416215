import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import bannerImg from "../Assets/Diptee Mam Banner.jpg";
import profileImg from "../Assets/Diptee Mam2.png";

const DeeptiJandail = () => {
  return (
    <div>
      <Header />
      <div style={{ backgroundColor: "#F9DB47" }}>
          <div className="container-fluid p-0">
            <img
              src={bannerImg}
              alt="Deepti Jandail Banner"
              className="img-fluid w-100"
              style={{ maxHeight: "600px", objectFit: "cover" }}
            />
          </div>
        {/* </div> */}

        {/* Awards Section */}
        <div className="py-4">
          <div className="container">
            <div className="text-center">
              <h1 className="display-3 fw-bold" style={{ color: "#CA7535" }}>
                Deepti N. Jandial
              </h1>
              <p className="fs-4" style={{ color: "#CA7535" }}>
                Transforming Education with Excellence
              </p>
            </div>

            <div className="row justify-content-center align-items-center mt-4">
              <div className="col-lg-6">
                <div className="card shadow-lg p-4 bg-white rounded border-0">
                  <h2 className="text-center mb-4 text-primary fw-bold">
                    Awards & Achievements
                  </h2>
                  <ul className="list-unstyled">
                    <li className="mb-3">
                      <strong>
                        Published by the Jammu & Kashmir Government
                      </strong>{" "}
                      for the transformational work in Faculty Development
                      Programs.
                    </li>
                    <li className="mb-3">
                      <strong>Published under 9 publication houses</strong> of
                      Rajasthan & Punjab for the difference made in Learning for
                      Faculties.
                    </li>
                    <li className="mb-3">
                      <strong>
                        Awarded as Excellence in Instructional Leadership
                      </strong>{" "}
                      by the Uttarakhand Government under the banner of Time to
                      Grow.
                    </li>
                  </ul>
                </div>
              </div>

              {/* Profile Image Section */}
              <div className="col-lg-4 d-flex justify-content-center">
                <img
                  className="img-fluid rounded-circle shadow-lg p-2"
                  src={profileImg}
                  alt="Deepti Jandial"
                  style={{
                    width: "350px",
                    height: "350px",
                    objectFit: "contain",
                    backgroundColor: "#CA7535",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeeptiJandail;
